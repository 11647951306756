import * as React from 'react';

const InstagramIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg fill="none" viewBox="0 0 15 15" height="1em" width="1em" {...props}>
      <path fill="currentColor" d="M7.5 5a2.5 2.5 0 100 5 2.5 2.5 0 000-5z" />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M4.5 0A4.5 4.5 0 000 4.5v6A4.5 4.5 0 004.5 15h6a4.5 4.5 0 004.5-4.5v-6A4.5 4.5 0 0010.5 0h-6zM4 7.5a3.5 3.5 0 117 0 3.5 3.5 0 01-7 0zM11 4h1V3h-1v1z"
        clipRule="evenodd"
      />
    </svg>
  );
};

export { InstagramIcon };
